import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QrCodeIcon from '@mui/icons-material/QrCode';

const DetailPanel = ({ row }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const commentaire = row.commentaire || row.original?.commentaire || row._valuesCache?.comment;
  const customer = row.original?.customers?.[0] || {};
  const moisNoms = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  const raceMapping = {
    Noir: 'Black',
    Arabe: 'Maghrébin',
  };
  const bankName = customer?.nom_banque || 'N/A';

  const anciennete = customer?.anciennete; // Récupération de l'ancienneté

  // Fonction pour afficher l'ancienneté sous forme lisible
  const getAncienneteLabel = (value) => {
    switch (value) {
      case '0':
        return 'Nouveau';
      case '1':
        return '3 mois à 1 an';
      case '2':
        return '1 an à 3 ans';
      case '3':
        return '3 ans à 6 ans';
      case '4':
        return '+ 6 ans';
      default:
        return 'N/A'; // Valeur non reconnue
    }
  };

  const getRaceDisplay = (race) => {
    return raceMapping[race] || race || 'N/A';
  };

  const currentYear = new Date().getFullYear(); // Année actuelle

  const typeCard = customer?.identity[0]?.type_doc; // Récupère le type de carte
  const expiryYear = customer?.identity[0]?.expiry_date; // Extrait l'année d'expiration (YYYY)

  // Logique pour vérifier si la carte d'identité est encore valable
  let validityMessage = '';

  if (expiryYear && typeCard === 'CNI FR (Ancienne)') {
    const yearOfExpiry = parseInt(expiryYear);
    const validUntilYear = yearOfExpiry + 5; // Durée totale de validité est de 15 ans

    // Si l'année d'expiration est toujours valide
    if (yearOfExpiry >= currentYear) {
      
    } else if (currentYear <= validUntilYear) {
      validityMessage = `Carte d'identité valable jusqu'à ${validUntilYear}`; // Affichage de la validité
    } else {
      validityMessage = "Carte d'identité non valide"; // Carte expirée
    }
  }

  return (
    <Box sx={{ padding: isMobile ? 1 : 2 }}>
      <Grid container spacing={2}>
        {/* Commentaire */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: isMobile ? 1 : 2, backgroundColor: '#f5f5f5' }}>
            <Typography variant={isMobile ? 'subtitle1' : 'h6'} gutterBottom>
              Commentaire
            </Typography>
            <Typography variant={isMobile ? 'body2' : 'body1'}>{commentaire || 'N/A'}</Typography>
          </Paper>
        </Grid>

        {/* Identité et Informations fiscales */}
        <Grid item xs={12}>
          <Grid container spacing={isMobile ? 2 : 3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: isMobile ? 1 : 2 }}>
                <Typography variant={isMobile ? 'subtitle1' : 'h6'} gutterBottom>
                  Identité
                </Typography>
                {row.original?.customers?.flatMap((customer) =>
                  customer.identity?.length > 0 ? (
                    customer.identity.map((id, index) => (
                      <Box key={`${index}`} sx={{ mt: isMobile ? 1 : 2 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle2'>Type de document</Typography>
                            <Chip label={id.type_doc || 'N/A'} size='small' />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle2'>Qualité</Typography>
                            <Chip label={id.qualite || 'N/A'} size='small' />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle2'>Noir et Blanc</Typography>
                            <Chip
                              label={id.noiretblanc ? 'Oui' : 'Non'}
                              size='small'
                              color={id.noiretblanc ? 'primary' : 'default'}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle2'>SCAN</Typography>
                            <Chip
                              label={id.pdf ? 'Oui' : 'Non'}
                              size='small'
                              color={id.pdf ? 'primary' : 'default'}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle2'>Année de naissance</Typography>
                            <Typography>{id.birthdate || 'N/A'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle2'>Année d'expiration</Typography>
                            <Typography>{expiryYear || 'N/A'}</Typography>
                            {validityMessage && (
                              <Box sx={{ mt: 1 }}>
                                {' '}
                                <Chip
                                  label={validityMessage}
                                  color={validityMessage.includes('valable') ? 'success' : 'error'}
                                  size='small'
                                />
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle2'>Type</Typography>
                            <Typography>{getRaceDisplay(id.race)}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle2'>Pays</Typography>
                            <Typography>{id.country || 'N/A'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle2'>4 bords visibles</Typography>
                            <Chip
                              label={id['4_bords_visibles'] ? 'Oui' : 'Non'}
                              size='small'
                              color={id['4_bords_visibles'] ? 'primary' : 'default'}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle2'>Civilité</Typography>
                            <Typography>{id.civilite || 'N/A'}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle2'>Document supplémentaire</Typography>
                            <Chip
                              label={id.autre_doc ? 'Oui' : 'Non'}
                              size='small'
                              color={id.autre_doc ? 'primary' : 'default'}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    ))
                  ) : (
                    <Typography key='no-identity' variant={isMobile ? 'body2' : 'body1'}>
                      N/A
                    </Typography>
                  ),
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: isMobile ? 1 : 2, overflow: 'hidden' }}>
                <Typography variant={isMobile ? 'subtitle1' : 'h6'} gutterBottom>
                  Informations fiscales
                </Typography>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{ maxWidth: '100%', overflowX: 'auto' }}
                >
                  <Table size='small'>
                    <TableHead>
                      <TableRow key='tax-table'>
                        <TableCell>Année</TableCell>
                        <TableCell>Revenu</TableCell>
                        <TableCell>Parts</TableCell>
                        <TableCell>{isMobile ? 'Dom' : 'Domiciliation'}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.original?.customers?.flatMap((customer) =>
                        customer.tax?.map((t, index) => (
                          <TableRow key={`${index}`}>
                            <TableCell>{t.annee_impots || 'N/A'}</TableCell>
                            <TableCell>
                              {t.revenu != null
                                ? t.revenu.toLocaleString('fr-FR', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  })
                                : 'N/A'}
                            </TableCell>
                            <TableCell>{t.parts || 'N/A'}</TableCell>
                            <TableCell>{t.domiciliation || 'N/A'}</TableCell>
                          </TableRow>
                        )),
                      ) || (
                        <TableRow key='no-tax-info'>
                          <TableCell colSpan={isMobile ? 2 : 4}>N/A</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container alignItems='center' spacing={1} sx={{ p: 1, mt: 1 }}>
                  <Grid item xs={12}>
                    <Typography variant={isMobile ? 'body2' : 'subtitle1'}>
                      PDF :{' '}
                      {row.original?.customers[0]?.tax.some((t) => t.pdf) ? (
                        <Chip label='Oui' size='small' color='primary' />
                      ) : (
                        <Chip label='Non' size='small' color='default' />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>

              {/* Bulletin de paie QR Code */}
              <Paper elevation={3} sx={{ p: isMobile ? 1 : 2, mt: isMobile ? 2 : 3 }}>
                <Typography variant={isMobile ? 'subtitle1' : 'h6'} gutterBottom>
                  Bulletin de paie
                </Typography>
                <TableContainer component={Paper} elevation={0}>
                  <Table size='small'>
                    <TableHead>
                      <TableRow key='pay-table'>
                        <TableCell>Année</TableCell>
                        <TableCell>Mois</TableCell>
                        <TableCell>Montant</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.original?.customers?.flatMap((customer) =>
                        customer.pay
                          ?.sort((a, b) => {
                            const moisA = parseInt(a.mois);
                            const moisB = parseInt(b.mois);
                            return moisA - moisB;
                          })
                          .map((p, index) => (
                            <TableRow key={`${index}`}>
                              <TableCell>{p.annee || 'N/A'}</TableCell>
                              <TableCell>{`${moisNoms[parseInt(p.mois) - 1]}` || 'N/A'}</TableCell>
                              <TableCell>
                                {p.salaire != null
                                  ? p.salaire.toLocaleString('fr-FR', {
                                      style: 'currency',
                                      currency: 'EUR',
                                    })
                                  : 'N/A'}
                              </TableCell>
                            </TableRow>
                          )),
                      ) || (
                        <TableRow key='no-pay-info'>
                          <TableCell colSpan={3}>N/A</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container alignItems='center' spacing={1} sx={{ p: 1, mt: 1 }}>
                  <Grid item>
                    <Typography variant={isMobile ? 'body2' : 'subtitle1'}>QR Code :</Typography>
                  </Grid>
                  <Grid item>
                    {customer.qrcode_fdp ? (
                      <Chip
                        icon={<QrCode2Icon />}
                        label='Présent'
                        color='primary'
                        variant='outlined'
                      />
                    ) : (
                      <Chip
                        icon={<QrCodeIcon />}
                        label='Absent'
                        color='default'
                        variant='outlined'
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant={isMobile ? 'body2' : 'subtitle1'}>
                      PDF :{' '}
                      <Chip
                        label={customer.pay_pdf ? 'Oui' : 'Non'}
                        size='small'
                        color={customer.pay_pdf ? 'primary' : 'default'}
                      />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={isMobile ? 'body2' : 'subtitle1'}>
                      Banque :{' '}
                      <Chip
                        label={bankName}
                        size='small'
                        sx={{
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                        }}
                        color={bankName !== 'N/A' ? 'primary' : 'default'}
                      />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2'>
                      Ancienneté : <Chip label={getAncienneteLabel(anciennete)} size='small' />
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailPanel;
